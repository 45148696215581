@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');



body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', 'sans-serif' !important;
}



.form-control:focus {
  box-shadow: none !important;
}

.mySwiper .swiper-pagination-bullet {
  background: #0078D1;
  opacity: 1;
  height: 3px;
  border-radius: 50px;
}

.mySwiper .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #0078D1;
  border-radius: 50px;
  width: 34px;
  height: 3px;
}

.heading-holder h2 {
  font-size: 45px;
  font-weight: bold;
}

.heading-holder .our-text {
  color: #0078D1;
}

.sent-message-text p {
  font-size: 20px;
  margin-top: 6px;
}

@media(min-width:0px) and (max-width:486px) {

  .heading-holder h2 {
    font-size: 22px;
  }

  .heading-holder p {
    font-size: 14px;
  }

  .sent-message-text p {
    font-size: 15px;
    margin-top: 10px;
  }
}

@media(min-width:486px) and (max-width:767px) {
  .heading-holder h2 {
    font-size: 25px;
  }

  .sent-message-text p {
    font-size: 15px;
  }


}

@media(min-width:768px) and (max-width:992px) {
  .heading-holder h2 {
    font-size: 30px;
  }


}

@media(min-width:992px) and (max-width:1200px) {
  .heading-holder h2 {
    font-size: 35px;
  }
}

/* .main .modal{
  backdrop-filter: brightness(2);
} */