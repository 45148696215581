.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .Manufacturing_Unit_Card_img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder {
  position: relative;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .current-hover {
  position: absolute;
  bottom: 0px;
  background: transparent linear-gradient(180deg, #004e8700 0%, #004e87 100%);
  border-radius: 15px;
  width: 100%;
  height: 40%;
  transition: all 0s ease-in-out;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder {
  position: absolute;
  bottom: 20px;
  z-index: 1;
  left: 20px;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder h3 {
  text-align: left;
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  position: absolute;
  bottom: 0%;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #202020;
  opacity: 0;
  margin-bottom: 0px;
  transform: translateY(8px);
  transition: all 0.4s ease-in-out;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder:hover .current-hover {
  background: transparent linear-gradient(180deg, #ffffffa8 0%, #fffffffc 60%, #c5e6ff 100%);
  height: 100%;
  transition: all 0s ease-in-out;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder:hover .text-holder h3 {
  color: #000;
  transition: all 0.4s ease-in-out;
  bottom: 100%;
}

.Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder:hover .text-holder p {
  transform: translateY(0);
  opacity: 1;
  margin-bottom: 0px;
  transition: all 0.4s ease-in-out;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder h3 {
    font-size: 15px;
  }

  .Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder p {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder h3 {
    font-size: 15px;
  }

  .Manufacturing_Unit_Card .Manufacturing_Unit_Card_holder .text-holder p {
    font-size: 12px;
  }
}