.Manufacturing_Unit {
    margin-top: 5%;
    clip-path: polygon(0 5%, 51% 0, 100% 5%, 100% 99%, 51% 96%, 0 100%);
    background: #F1F1F1 0% 0% no-repeat padding-box;
    padding-top: 75px;
    padding-bottom: 5%;
}

.Manufacturing_Unit .Manufacturing_Unit_swiper {
    padding-bottom: 40px;
}

@media(min-width:0px) and (max-width:767px) {
    .Manufacturing_Unit {
        padding-bottom: 12%;
    }
}