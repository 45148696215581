.our-client-say {
    margin: 5% 0;
}

.our-client-say .heading-holder h2 {
    font-size: 45px;
    font-weight: bold;
}

.our-client-say .client-card {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 20px;

}

.our-client-say .client-card .name-holder h2 {
    font-size: 25px;
    font-weight: 500;
}

.our-client-say .client-card .name-holder p {
    font-size: 14px;
    color: #3E3E3E;
}

.our-client-say .client-card .contect-text p {
    color: #3E3E3E;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.our-client-say .silder-btn {
    position: relative;
}

.our-client-say .silder-btn .back-btn,
.our-client-say .silder-btn .next-btn {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    z-index: 2;
    font-size: 20px;
    width: 30px;
    height: 30px;
    background: #FFF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #003D88;
    text-align: center;
    transition: color 0.2s ease, background 0.2s ease;
}

.our-client-say .silder-btn .back-btn {
    left: 47%;
}

.our-client-say .silder-btn .next-btn {
    right: 47%;
}

.our-client-say .swiper-button-prev.swiper-button-disabled,
.our-client-say .swiper-button-next.swiper-button-disabled {
    display: none;
}

.our-client-say .silder-btn .back-btn:hover,
.our-client-say .silder-btn .next-btn:hover {
    color: #964400;
    width: 30px;
    height: 30px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    text-align: center;
}


@media(min-width:0px) and (max-width:486px) {

    .our-client-say .heading-holder h2 {
        font-size: 22px;
    }

    .our-client-say .heading-holder p {
        font-size: 14px;
    }

    .our-client-say .client-card .name-holder h2 {
        font-size: 16px;
        font-weight: 500;
    }

    .our-client-say .client-card .contect-text p {
        font-size: 13px;
    }

    .our-client-say .silder-btn .back-btn {
        left: 40%;

    }

    .our-client-say .silder-btn .next-btn {
        right: 40%;

    }
}

@media(min-width:486px) and (max-width:767px) {
    .our-client-say .heading-holder h2 {
        font-size: 25px;
    }

    .our-client-say .client-card .name-holder h2 {
        font-size: 18px;
        font-weight: 500;
    }

    .our-client-say .client-card .contect-text p {
        font-size: 14px;
    }

    .our-client-say .silder-btn .back-btn {
        left: 40%;

    }

    .our-client-say .silder-btn .next-btn {
        right: 40%;

    }
}

@media(min-width:768px) and (max-width:992px) {
    .our-client-say .heading-holder h2 {
        font-size: 30px;
    }



    .our-client-say .client-card .name-holder h2 {
        font-size: 20px;
        font-weight: 500;
    }

    .our-client-say .client-card .contect-text p {
        font-size: 14px;
    }

    .our-client-say .silder-btn .back-btn {
        left: 45%;

    }

    .our-client-say .silder-btn .next-btn {
        right: 45%;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .our-client-say .heading-holder h2 {
        font-size: 35px;
    }

    .our-client-say .client-card .name-holder h2 {
        font-size: 20px;
        font-weight: 500;
    }

    .our-client-say .client-card .contect-text p {

        font-size: 14px;
    }

    .our-client-say .silder-btn .back-btn {
        left: 45%;

    }

    .our-client-say .silder-btn .next-btn {
        right: 45%;

    }
}