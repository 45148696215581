.why-should {
    background-image: url("../../../../public/assets/images/home/WHYCHOOSE.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* margin: 8% 0; */
    padding-top: 93px;
    padding-bottom: 9%;
    clip-path: polygon(0 5%, 50% 0, 100% 5%, 100% 100%, 50% 90%, 0 101%);
}

.why-should .main-heading h2 {
    color: #FFF;
    font-size: 45px;
    font-weight: bold;
}

@media(min-width:0px) and (max-width:486px) {
    .why-should {
        padding-bottom: 38%;
        clip-path: none;
        padding-top: 58px;
    }

    .why-should .main-heading h2 {
        font-size: 20px;
    }

    /* .why-should {
        clip-path: none;
    } */
}

@media(min-width:486px) and (max-width:767px) {
    .why-should .main-heading h2 {
        font-size: 25px;
    }

    .why-should {
        padding-bottom: 25%;
        clip-path: none;
        padding-top: 58px;
    }

}

@media(min-width:768px) and (max-width:992px) {
    .why-should .main-heading h2 {
        font-size: 30px;
    }

    .why-should {
        padding-top: 75px;
        padding-bottom: 14%;
        clip-path: none;
        /* padding-top: 58px; */

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .why-should .main-heading h2 {
        font-size: 35px;
    }

    .why-should {
        padding-bottom: 14%;

    }
}