.we-belive {
    margin-top: 5%;
    margin-bottom: 10%;
}

.we-belive .quatation-img .comma-img {
    width: 35px;
    height: 71px;
}

.we-belive .heading-holder {
    text-align: right !important;
    margin-left: auto;
}

.we-belive .heading-holder h3 {
    font-size: 32px;
    font-weight: 500;
}

.we-belive .heading-holder h4 {
    font-size: 43px;
    font-weight: bold;
    color: #0078D1;
}

.we-belive .heading-holder h5 {
    font-size: 45px;
    font-weight: bold;
    color: #0078D1;
}

.we-belive .social-img-logo {
    border-right: 1px solid #000;
    margin-top: 189px;
    padding-right: 42px;

}

.we-belive .image-holder .certificate-img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

@media(min-width:0px) and (max-width:767px) {
    .we-belive .heading-holder h3 {
        font-size: 15px;

    }

    .we-belive .heading-holder h4 {
        font-size: 16px;
    }

    .we-belive .heading-holder h5 {
        font-size: 18px;

    }

    .we-belive .social-img-logo {
        margin-top: 10px;
        padding-right: 20px;
        margin-bottom: 10px;
    }

    .we-belive .quatation-img .comma-img {
        width: 25px;
        height: 44px;
    }

    .we-belive .heading-holder {
        /* text-align: center !important; */
        /* margin: auto; */
    }

    .we-belive {
        margin-bottom: 25%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .we-belive .heading-holder h3 {
        font-size: 18px;

    }

    .we-belive .heading-holder h4 {
        font-size: 18px;
    }

    .we-belive .heading-holder h5 {
        font-size: 20px;

    }

    .we-belive .social-img-logo {
        margin-top: 106px;
        padding-right: 20px;
    }

    .we-belive .quatation-img .comma-img {
        width: 25px;
        height: 44px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .we-belive .heading-holder h3 {
        font-size: 28px;

    }

    .we-belive .heading-holder h4 {
        font-size: 35px;
    }

    .we-belive .heading-holder h5 {
        font-size: 37px;

    }

    .we-belive .social-img-logo {
        margin-top: 106px;
        padding-right: 27px;
    }
}