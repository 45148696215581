.latest-technology {
    margin-top: 20px;
}

.latest-technology .technology-card {
    background: #ECECEC 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 20px 30px 0px 30px;
}

.latest-technology .technology-card .icon-img {
    width: 50px;
    height: 50px;
    margin-top: 29px;
    object-fit: contain;
}

.latest-technology a {
    color: #000;
}

.latest-technology .technology-card .text-holder {
    text-align: end;
}

.latest-technology .technology-card .text-holder .border-line {
    border: 0.5px solid #964400;
    opacity: 0.5;
    width: 150px;
    margin-left: auto;
}

.latest-technology .technology-card .text-holder h2 {
    font-size: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.latest-technology .technology-card .text-holder p {
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

@media(min-width:0px) and (max-width:767px) {
    .latest-technology {
        margin-top: 20px;
    }

    .latest-technology .technology-card .text-holder h2 {
        font-size: 18px;

    }

    .latest-technology .technology-card .text-holder p {
        font-size: 12px;
        font-weight: 500;
    }

    .latest-technology .technology-card .icon-img {
        margin-top: 13px;
    }
}


@media(min-width:768px) and (max-width:991px) {
    .latest-technology {
        margin-top: 20px;
    }

    .latest-technology .technology-card .text-holder h2 {
        font-size: 20px;

    }

    .latest-technology .technology-card .text-holder p {
        font-size: 14px;
        font-weight: 500;
    }

    .latest-technology .technology-card .icon-img {
        margin-top: 13px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .latest-technology {
        margin-top: 20px;
    }

    .latest-technology .technology-card .text-holder h2 {
        font-size: 20px;

    }
}

@media(min-width:1200px) and (max-width:1400px) {

    .latest-technology {
        margin-top: 20px;
    }

    .latest-technology .technology-card .text-holder p {
        font-size: 15px;
        font-weight: 500;
    }


}