.blog {
    margin-top: 2%;
    margin-bottom: 5%;
}

.blog .update-card .update-img {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    aspect-ratio: 1 / 0.6;
}

.blog .update-card .update-img .blog-update-img {
    width: 100%;
    border-radius: 15px;
    transition: 0.3s;
}

.blog .update-card:hover .update-img .blog-update-img {
    transform: scale(1.1);
    transition: 0.3s;
    border-radius: 15px;
}

.blog .top-heading h2 {
    font-size: 22px;
    font-weight: bold;

}

.blog .update-card .heading-text h3 {
    font-size: 17px;
    font-weight: bold;
    margin: 10px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.blog .update-card .heading-text p {
    font-size: 13px;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.blog .update-card .heading-text .readmore-p {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.blog .update-card:hover .heading-text .readmore-p {
    color: #0078D1;
}

.blog .recent-post-card {
    background: #E3F0FF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px;
}

.blog .recent-post-card .recent-img {
    aspect-ratio: 1 / 0.6;
}

.blog .recent-post-card .recent-img .blog-recent-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.blog .recent-post-card h3 {
    font-size: 13px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin: 10px 0px;
}

.blog .recent-post-card p {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    margin-bottom: 0%;
}

.blog .recent-post-card .date-holder span {
    font-size: 11px;
    font-weight: 500;
}

.blog .recent-post-card .icon-holder .fa-icon {
    font-size: 11px;
    margin-right: 4px;
}

/* .blog .pagination {
    background-color: transparent !important;
    --bs-pagination-bg: transparent !important;
} */

/* .blog .page-item:first-child .page-link {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
} */

.blog .pagination p {
    margin-bottom: 0%;
}

.blog .pagination a:hover {
    background: #B1D4FF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    box-shadow: none;
    color: #000;
    border: none;
}

.blog .pagination .active a {
    background: #B1D4FF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    box-shadow: none;
    color: #000;
    cursor: default;
    border: none;
}

.blog .pagination a:focus {
    background: #B1D4FF 0% 0% no-repeat padding-box;
    border-radius: 3px;
    box-shadow: none;
    color: #000;
    border: none;
}


.blog .page-item .page-link {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    box-shadow: none;
    color: #000;
    margin-right: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* .blog .page-item:not(:first-child) .page-link {
    border-width: 0.5px;
    border-radius: 50px;
} */

.blog .page-link:active {
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border: 2px solid #000000;
    border-radius: 50px;
    background: #d2e3ff;
}

.blog a {
    color: #000;
}

@media(min-width:0px) and (max-width:767px) {
    .blog .update-card .heading-text h3 {
        font-size: 14px;

    }

    .blog .top-heading h2 {
        font-size: 20px;
    }

    .blog .recent-post-card h3 {
        font-size: 12px;
    }

    .blog .recent-post-card p {
        font-size: 12px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .blog .update-card .heading-text h3 {
        font-size: 14px;

    }

    .blog .top-heading h2 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .blog .update-card .heading-text h3 {
        font-size: 14px;
        margin: 5px 0px;
    }

    .blog .recent-post-card h3 {
        font-size: 12px;
    }

    .blog .recent-post-card p {
        font-size: 12px;
    }

    .blog .recent-post-card .date-holder span {
        font-size: 9px;
        font-weight: 500;
    }
}