.why-should-card {
    background: #FFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;
    color: black;
    height: 100%;
}

.second-card {
    background: #964400;
    color: white;
}

.why-should-card .heading-holder h2 {
    font-size: 25px;
    font-weight: bold;
}

.why-should-card .text-holder h3 {
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.why-should-card .text-holder p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.second-card .heading-holder h2,
.second-card .text-holder h3,
.second-card .text-holder p {
    color: white;
}

.why-should-card .icon-holder .queta-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}
@media(min-width:0px) and (max-width:767px) {
    .why-should-card .heading-holder h2 {
        font-size: 16px;
    }

    .why-should-card .text-holder p {
        font-size: 13px;
    }

    .why-should-card .text-holder h3 {
        font-size: 14px;
    }
}
@media(min-width:992px) and (max-width:1200px) {
    .why-should-card .heading-holder h2 {
        font-size: 20px;
    }

    .why-should-card .text-holder p {
        font-size: 13px;
    }

    .why-should-card .text-holder h3 {
        font-size: 15px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .why-should-card .heading-holder h2 {
        font-size: 20px;
    }

    .why-should-card .text-holder p {
        font-size: 13px;
    }

    .why-should-card .text-holder h3 {
        font-size: 15px;
    }
}