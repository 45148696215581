.we-are-home {
    margin: 5% 0;
}

.we-are-home .heading-holder h2 {
    font-size: 45px;
    font-weight: bold;
}

.we-are-home .heading-holder h3 {
    font-size: 45px;
    font-weight: bold;
    color: #0078D1;
    margin-bottom: 20px;
}

.we-are-home .heading-holder h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.we-are-home .heading-holder p {
    font-size: 16px;
    font-weight: 500;
}

.we-are-home .quatation-img .queta-img {
    width: 21px;
    height: 38px;
}

.we-are-home .border-icon {
    background-color: #0078D1;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    color: #FFF;
    margin-right: 18px;
}

.we-are-home .fb-icon {
    margin-top: 5px;
    color: #FFF;
}

.we-are-home .image-holder .builder-img {
    border-radius: 15px;
    width: 100%;
    aspect-ratio: 1 / 1;
}

.we-are-home .border-line {
    border: 1px solid #964400; 
     opacity: 0.5;
}

.we-are-home .social-img-logo {
    margin-top: 40px;
}

@media(min-width:0px) and (max-width:767px) {
    .we-are-home .heading-holder h3 {
        font-size: 20px;
    }

    .we-are-home .heading-holder h2 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .we-are-home .heading-holder h4 {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .we-are-home .heading-holder p {
        font-size: 14px;
    }

    .we-are-home .image-holder {
        margin-top: 10px;
    }

    .we-are-home .social-img-logo {
        margin-top: 20px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .we-are-home .heading-holder h3 {
        font-size: 25px;
    }

    .we-are-home .heading-holder h2 {
        font-size: 25px;
        margin-bottom: 8px;
    }

    .we-are-home .heading-holder h4 {
        font-size: 15px;
        margin-bottom: 8px;
    }

    .we-are-home .heading-holder p {
        font-size: 14px;
    }

    .we-are-home .social-img-logo {
        margin-top: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .we-are-home .heading-holder h3 {
        font-size: 33px;
    }

    .we-are-home .heading-holder h2 {
        font-size: 33px;
    }

    .we-are-home .heading-holder h4 {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .we-are-home .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {}