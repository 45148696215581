.conter-section .text-holder {
    border-left: 2px solid #20202078;
}

.conter-section .icon-holder {
    margin-right: 8px;
}

.conter-section .text-holder h4 {
    color: #0078D1;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 0px;
}

.conter-section .text-holder p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;

}

.conter-section .counter-row {
    justify-content: space-between;
}
.conter-section .icon-holder .location-icon {
    width: 32px;
    height: 32px;
}
@media(min-width:0px) and (max-width:767px) {
    .conter-section .text-holder h4 {
        color: #0078D1;
        font-size: 25px;
    }

    .conter-section .text-holder p {
        color: #000;
        font-size: 12px;
        font-weight: 500;
    }

    .conter-section .counter-row {
        justify-content: unset;
    }

    .location-adddd {
        margin: 10px 0px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .conter-section .text-holder h4 {
        color: #0078D1;
        font-size: 35px;
    }

    .conter-section .text-holder p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    .conter-section .counter-row {
        justify-content: unset;
    }

    .location-adddd {
        margin: 10px 0px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .conter-section .text-holder h4 {
        color: #0078D1;
        font-size: 40px;
    }

    .conter-section .text-holder p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }
}