.main-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1024;
}

.main-header .headlogo {
    width: 170px;
    object-fit: contain;
}

.main-header .top-header {
    background-color: white;
}

.main-header .second-header {
    background: #3E3E3E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

.main-header .top-header .border-icon {
    background-color: #0078D1;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    color: #FFF;
    margin-right: 18px;
}

.main-header .top-header span {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
}

.main-header .top-header .social-icon {
    margin-top: 25px;
}

.main-header .top-header .fb-icon {
    margin-top: 5px;
}

.main-header .top-header .image-holder .headlogo {
    width: 170px;
}

.main-header .navbar-nav .nav-link {
    color: #fff;
    font-size: 15px;
    margin: 0px 15px;
}

/* .main-header .navbar-nav .nav-link:hover {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #FFF;
} */

.main-header .navbar-nav .nav-link.active {
    color: #fff;
    font-size: 15px;
    margin: 0px 15px;
}

.main-header .second-header .nav-link-with-borders {
    position: relative;
}

.main-header .second-header .nav-link-with-borders.active .white-border {
    background-color: #FFF;

}

.main-header .second-header .white-border {
    position: absolute;
    bottom: 5px;
    height: 2px;
    width: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
}

.main-header .second-header .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
}

.main-header .second-header .navbar-nav .nav-link.active {
    display: block;
}

@media(min-width:0px) and (max-width:321px) {
    .main-header .top-header .fb-icon {
        font-size: 10px !important;
        margin-bottom: 3px !important;
    }

    .main-header .top-header .border-icon {
        width: 20px !important;
        height: 20px !important;
        margin-right: 1px;
    }

    .main-header .top-header span {
        font-size: 8px;
    }

    .main-header .top-header .image-holder .headlogo {
        width: 100px;
    }

    .main-header .top-header .social-icon {
        margin-top: 17px;
    }
}

@media(min-width:321px) and (max-width:486px) {
    .main-header .top-header .image-holder .headlogo {
        width: 100px;
    }

    .main-header .top-header .social-icon {
        margin-top: 17px;
    }

    .main-header .top-header span {
        font-size: 10px;
    }

    .main-header .top-header .border-icon {
        width: 22px;
        height: 22px;
        margin-right: 2px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .main-header .top-header .fb-icon {
        margin-top: 0px;
        font-size: 12px;
     
    }
}

@media(min-width:486px) and (max-width:767px) {
    .main-header .top-header .image-holder .headlogo {
        width: 120px;
    }

    .main-header .top-header .social-icon {
        margin-top: 17px;
    }

    .main-header .top-header span {
        font-size: 10px;
    }

    .main-header .top-header .border-icon {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .main-header .top-header .fb-icon {
        margin-top: 0px;
        font-size: 12px;
    }
}

@media(min-width:0px) and (max-width:992px) {
    .main-header .second-header .navbar-toggler {
        background-color: #000 !important;
        /* margin-left: auto; */
        width: 44px;
        height: 36px;
        padding: 0px;
    }

    .main-header .second-header .navbar-toggler-icon {
        filter: brightness(0) invert(1);
        width: 20px;
    }

    .main-header .second-header .navbar-toggler:focus {
        box-shadow: none;
    }

    .main-header .second-header .white-border {
        position: absolute;
        bottom: 5px;
        height: 2px;
        width: 5%;
        left: 3%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
    }

    .main-header .second-header .navbar-nav {
        margin-top: 10px;
    }

    .main-header .navbar-nav .nav-link {
        padding-left: 0px !important;
    }

    .main-header .navbar-nav .nav-link.active {
        padding-left: 0px !important;
    }
}