.footer {
    background-image: url('../../../public/assets/images/logo/footerbanner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    clip-path: polygon(50% 0%, 100% 8%, 100% 100%, 0 100%, 0 8%);
    padding-top: 70px;
    padding-bottom: 10px;
}

.footer .header-logo {
    width: 170px;
}

.footer .all-nav-link {
    margin-bottom: 55px;
    margin-top: 34px;
}

.footer .nav-link {
    color: #fff;
    font-size: 15px;

}

.footer .nav-link:hover {
    color: #fff;
    font-size: 15px;

}

.footer .nav-link.active {
    color: #fff;
    font-size: 15px;

}

.footer .nav-link:focus {
    color: #fff;
    font-size: 15px;

}

.footer .copyright-holder {
    margin-top: 100px;
}

.footer .copyright-holder p {
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
}

.footer a {
    color: #fff;
}

.footer .icon-holder {
    margin-right: 8px;
}

.footer .text-holder {
    border-left: 1px solid #fff;
    padding-bottom: 15px;
}

.footer .text-holder p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 5px;
}
.footer .location-icon {
    width: 24px;
    margin: 0px 10px;
}

@media(min-width:0px) and (max-width:992px) {
    .footer .text-holder p {
        font-size: 12px;
    }

    .footer .location-icon {
        width: 24px;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .footer .copyright-holder {
        margin-top: 50px;
    }

    .footer .location-adddd {
        margin-top: 10px;
    }

    .footer .nav-link {
        color: #fff;
        font-size: 13px;

    }

    .footer .nav-link:hover {
        color: #fff;
        font-size: 13px;

    }

    .footer .nav-link.active {
        color: #fff;
        font-size: 13px;

    }

    .footer .nav-link:focus {
        color: #fff;
        font-size: 13px;

    }

    .footer {
        clip-path: polygon(50% 0%, 100% 3%, 100% 100%, 0 100%, 0 3%);

    }
}