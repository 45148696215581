.experince-section {
    background-image: url('../../../../../public/assets/images/services/experience-banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 9%;
    clip-path: polygon(0 12%, 50% 0, 100% 11%, 100% 100%, 50% 87%, 0 100%);
    position: relative;
}

.experince-section .text-holderr {
    position: relative;
    z-index: 1;
    margin-bottom: 84px;
}

.experince-section .text-holderr h3 {
    font-size: 18px;
    color: #FFF;
    font-weight: 400;
}

.experince-section .overlay-color {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: transparent linear-gradient(180deg, #004E87A6 0%, #002744 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    clip-path: polygon(0 12%, 50% 0, 100% 11%, 100% 100%, 50% 87%, 0 100%);

}

.experince-section .text-holder {
    border-left: 2px solid #ffffffa1;
    z-index: 1;

}

.experince-section .icon-holder .location-icon {
    width: 32px;
    height: 32px;
}

.experince-section .icon-holder {
    margin-right: 8px;
    z-index: 1;
}

.experince-section .text-holder h4 {
    color: #FFF;
    font-size: 50px;
}

.experince-section .text-holder p {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0%;
}

.experince-section .counter-row {
    justify-content: space-between;
}

@media(min-width:0px) and (max-width:767px) {
    .experince-section {
        padding-bottom: 22%;
    }

    .experince-section .text-holder h4 {

        font-size: 25px;
    }

    .experince-section .text-holder p {

        font-size: 12px;
        font-weight: 500;
    }

    .experince-section .counter-row {
        justify-content: unset;
    }

    .location-adddd {
        margin: 10px 0px;
    }

    .experince-section .text-holderr h3 {
        font-size: 13px;
        margin-bottom: 40px;
    }

    .experince-section .text-holderr {
        margin-bottom: 37px;
    }

    .experince-section {
        clip-path: polygon(0 4%, 50% 0, 100% 3%, 100% 100%, 50% 94%, 0 100%);

    }

    .experince-section .overlay-color {
        clip-path: polygon(0 4%, 50% 0, 100% 3%, 100% 95%, 50% 94%, 0 95%);

    }
}

@media(min-width:768px) and (max-width:992px) {
    .experince-section .text-holder h4 {

        font-size: 35px;
    }

    .experince-section .text-holder p {

        font-size: 14px;
        font-weight: 500;
    }

    .experince-section .counter-row {
        justify-content: unset;
    }

    .location-adddd {
        margin: 10px 0px;
    }

    .experince-section {
        padding-bottom: 15%;
    }

    .experince-section .text-holderr {
        margin-bottom: 37px;
    }
    .experince-section {
        clip-path: polygon(0 3%, 50% 0, 100% 3%, 100% 100%, 50% 94%, 0 100%);

    }

    .experince-section .overlay-color {
        clip-path: polygon(0 4%, 50% 0, 100% 3%, 100% 97%, 50% 94%, 0 97%);

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .experince-section .text-holder h4 {
        font-size: 40px;
    }

    .experince-section .text-holder p {
        font-size: 14px;
        font-weight: 500;
    }

    .experince-section {
        padding-bottom: 15%;
    }
}