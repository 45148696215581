.category-tab {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px 11px 87px 15px;
    margin-bottom: 30px;
}

.category-tab h3 {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #964400;
    padding-bottom: 10px;
}

.category-tab .nav-pills .nav-link {
    color: #000;
    font-size: 16px;
    padding-left: 1px;
}

.category-tab .nav-pills .nested-link {
    font-size: 14px;
}

.category-tab .nav-pills .nested-link.active {
    font-size: 14px !important;
    text-decoration: underline !important;
}

.category-tab .nav-pills .nav-link.active {
    font-size: 16px;
    color: #0078D1;
    background-color: transparent;
    border-radius: 0%;
    font-weight: 500;
    padding-left: 1px;
}

.category-tab .icon-fa {
    font-size: 10px;
    margin-right: 10px;
}

.category-tab .icon-holder {
    margin-right: 10px;
}

.category-tab .icon-holder .call-icon {
    color: #964400;
}

.category-tab .address-holder p {
    font-size: 15px;
    color: #000;
}

.category-tab .accordion-button:not(.collapsed) {
    color: #0078D1;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    padding-bottom: 0%;
    font-weight: 500;
    padding-left: 1px;
}

.category-tab .accordion-button {
    color: #000;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    padding-bottom: 7%;
    padding-top: 10px;
    padding-right: 0%;
    padding-left: 1px;

}

.category-tab .accordion-button:focus {
    box-shadow: none;
}

.category-tab .accordion-item {

    background-color: transparent;
    border: none;
}

.category-tab .accordion-button:not(.collapsed)::after {
    display: none;
}

.category-tab .accordion-button::after {
    display: none;
}

@media(min-width:0px) and (max-width:1200px) {
    .category-tab .nav-pills .nav-link {
        font-size: 13px;
        padding-left: 1px;
    }

    .category-tab .nav-pills .nav-link.active {
        font-size: 13px;
        padding-left: 1px;
    }

    .category-tab h3 {
        font-size: 17px;

    }

    .category-tab .accordion-button:not(.collapsed) {
        font-size: 13px;
        padding-left: 1px;
    }

    .category-tab .accordion-button {
        font-size: 13px;
        padding-left: 1px;
    }
}