.our-services-process {
    margin-top: 3%;
    margin-bottom: 7%;
}

.our-services-process .heading-holder p {
    font-size: 19px;
    font-weight: 500;
}

.our-services-process .circle-holder {
    background: #0078D1;
    text-align: center;
    border-radius: 100px;
    margin: auto;
    width: 108px;
    height: 108px;
}

.our-services-process .circle-holder img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin-top: 29px;
}

.our-services-process .box-holder p {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;

}

.our-services-process .circle-blue {
    background: #004E87;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    margin: 0px 5px;
}

.our-services-process .line-blue {
    background: #004E87;
    width: 40px;
    height: 2px;
    margin-top: 3px;
}

.our-services-process .line-circle-drow {
    margin-top: -100px;
    margin-left: -48px;
    display: flex;
}

.our-services-process .services-row {
    justify-content: center;
    margin-top: 7%;
}

.our-services-process .order-box {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    margin-top: 28%;
}

.our-services-process .order-box .order-text {
    margin-left: 74px;
}

.our-services-process .order-box .order-text p {
    color: #004E87;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.our-services-process .order-box .order-text span {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}

.our-services-process .order-box .image-icon {
    position: absolute;
    top: -23px;
    left: 13px;
    background: #0078D1 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 56px;
    height: 55px;
    text-align: center;

}

.our-services-process .order-box .image-icon .order-icon {
    width: 35px;
    height: 35px;
    object-fit: contain;
    margin-top: 10px;
}

@media(min-width:0px) and (max-width:767px) {
    .our-services-process .box-holder p {
        font-size: 12px;
    }

    .our-services-process .services-row {
        justify-content: unset;
    }

    .our-services-process .circle-holder {
        width: 80px;
        height: 80px;
    }

    .our-services-process .circle-holder img {
        width: 35px;
        height: 35px;
        margin-top: 23px;
    }

    .our-services-process .line-circle-drow {
        display: none;
    }

    .our-services-process .order-box {
        margin-top: 13%;
    }

    .our-services-process .order-box .order-text span {
        font-size: 14px;

    }

    .our-services-process .order-box .order-text p {
        font-size: 17px;

    }

    .our-services-process {
        margin-bottom: 15%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .our-services-process .box-holder p {
        font-size: 12px;
    }

    .our-services-process .services-row {
        justify-content: space-between;
    }

    .our-services-process .circle-holder {
        width: 80px;
        height: 80px;
    }

    .our-services-process .circle-holder img {
        width: 35px;
        height: 35px;
        margin-top: 23px;
    }

    .our-services-process .line-circle-drow {
        display: none;
    }

    .our-services-process .order-box .order-text span {
        font-size: 14px;

    }

    .our-services-process .order-box .order-text p {
        font-size: 17px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .our-services-process .box-holder p {
        font-size: 12px;
    }

    .our-services-process .services-row {
        justify-content: space-between;
    }

    .our-services-process .line-circle-drow {
        margin-left: -73px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .our-services-process .box-holder p {
        font-size: 14px;


    }
}