.enqiry-modal .modal-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    border: none;
    padding: 0px 65px 0px 65px;
}

.enqiry-modal .modal-title {
    margin: auto;
    color: #004E87;
    font-size: 35px;
    font-weight: bold;
}

.enqiry-modal .modal-header {
    border-bottom: none;
}

.enqiry-modal .phone-input-container {
    display: flex !important;
    align-items: center !important;
}

.enqiry-modal .phone-input-button {
    flex-shrink: 0 !important;
    margin-right: 10px !important;
    /* Adjust the space between the flag and input as needed */
}

.enqiry-modal .phone-input-field {
    flex-grow: 1 !important;
}

.enqiry-modal .react-tel-input .form-control {
    font-size: 16px;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    border-radius: 5px;
    width: 100%;
    outline: none;
    padding: 7.5px 16px 9.5px 60px !important;
    transition: box-shadow ease .25s, border-color ease .25s;
    color: #495057;
}

.enqiry-modal .form-control {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.enqiry-modal .form-control:focus {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.enqiry-modal .form-select {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.enqiry-modal .textarea-class {
    height: auto !important;
}

.enqiry-modal .form-select:focus {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.enqiry-modal .form-control::placeholder {
    color: #707070;
    font-size: 14px;

}

.enqiry-modal .react-select-container {
    margin-bottom: 1rem;
}

.enqiry-modal .react-select__control {
    height: 38px;
}

.enqiry-modal .react-select__placeholder {
    color: #6c757d;
    /* Bootstrap's default placeholder color */
}

.enqiry-modal .form-label {
    font-size: 15px;
    font-weight: 500;
}

@media(min-width:0px) and (max-width:767px) {
    .enqiry-modal .modal-title {
        font-size: 25px;
    }

    .enqiry-modal .modal-content {
        padding: 0px 0px 0px 0px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .enqiry-modal .modal-title {
        font-size: 30px;
    }

    .enqiry-modal .modal-content {
        padding: 0px 20px 0px 20px;
    }
}