.button-holder .blue-btn {
    background: #0078D1 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #FFF;
    font-size: 15px;
    border: 3px solid #004E87;
    padding: 5px 20px;
    margin: 1px 10px;
    transition: transform 0.3s ease;
    /* Add transition for smooth zoom */
}

.button-holder .blue-btn:hover {
    transform: scale(1.1);
}

.button-holder .gray-btn {
    background: #DEDEDE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #000;
    font-size: 15px;
    border: 3px solid #A7A7A7;
    padding: 5px 20px;
    margin: 1px 10px;
    transition: transform 0.3s ease;

}

.button-holder .blue-btn:active {
    background: #0078D1 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #FFF;
    font-size: 15px;
    border: 3px solid #004E87;
    padding: 5px 20px;
    margin: 1px 10px;
    transition: transform 0.3s ease;
}

.button-holder .gray-btn:active {
    background: #DEDEDE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #000;
    font-size: 15px;
    border: 3px solid #A7A7A7;
    padding: 5px 20px;
    margin: 1px 10px;
    transition: transform 0.3s ease;
}

.button-holder .blue-btn:hover {

    background: #0078D1 0% 0% no-repeat padding-box;
    transform: scale(1.1);
    border: 3px solid #004E87;
}

.button-holder .gray-btn:hover {
    transform: scale(1.1);
    background: #DEDEDE 0% 0% no-repeat padding-box;
    border: 3px solid #A7A7A7;
    color: #000;
}

@media(min-width:0px) and (max-width:767px) {
    .button-holder .blue-btn {
        padding: 4px 16px;
        font-size: 12px;
    }

    .button-holder .gray-btn {
        padding: 4px 16px;
        font-size: 12px;

    }

    .button-holder .blue-btn:hover {
        font-size: 13px;
    }

    .button-holder .gray-btn:hover {
        font-size: 13px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .button-holder .blue-btn {
        padding: 4px 16px;
        font-size: 14px;
    }

    .button-holder .gray-btn {
        padding: 4px 16px;
        font-size: 14px;

    }

    .button-holder .blue-btn:hover {
        font-size: 15px;
    }

    .button-holder .gray-btn:hover {
        font-size: 15px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .video-holder .button-holder {
        bottom: 69px;

    }
}

@media(min-width:1500px) and (max-width:1800px) {
    .video-holder .button-holder {
        bottom: 69px;

    }
}