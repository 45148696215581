.product-details .product-image-card {
    position: relative;
    margin-bottom: 9%;
}

.product-details .product-icon {
    width: 100%;
    height: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    aspect-ratio: 1/1;
    background-position: center;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 15px;

}

.product-details .product-icon .product-img {
    width: 200px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.product-details .button-holder {
    margin-top: -18px;
}

.product-details .details-text {
    margin-bottom: 9%;
}

.product-details .details-text h2 {
    font-size: 25px;
    font-weight: 500;
}

.product-details .details-text p {
    color: #707070;
    font-size: 15px;
    font-weight: 500;
}

.product-details .details-text span {
    font-size: 16px;
    text-align: justify;
}

.product-details .text-holder h3 {
    font-size: 18px;
    font-weight: 600;
}

.product-details .text-holder h4 {
    font-size: 16px;
    font-weight: 500;
}

.product-details .border-line {
    border-right: 2px solid #0078D1;
}

.product-details .text-holder h2 {
    font-size: 20px;
    font-weight: 500;
}

.product-details .text-holder p {
    font-size: 15px;
}

.product-details .text-overview {
    display: flex;
}

.product-details .border-text {
    border-right: 2px solid #202020;
}

.product-details .text-overview h4 {
    font-size: 16px;
    color: #202020;
    font-style: italic;
    margin-right: 7px;
    padding-right: 19px;
}

.product-details .form-heading h3 {
    color: #202020;
    font-size: 23px;
    font-weight: 500;
}

.product-details .form-heading h3::after {
    content: "";
    display: block;
    width: 47%;
    height: 1px;
    background-color: #964400;
    position: absolute;
    margin-left: 85px;
    z-index: -2;
    margin-top: -14px;
}

@media(min-width:0px) and (max-width:767px) {
    .product-details .details-text h2 {
        font-size: 21px;

    }

    .product-details .details-text p {
        font-size: 14px;
    }

    .product-details .details-text span {
        font-size: 14px;
    }

    .product-details .text-holder h3 {
        font-size: 15px;

    }

    .product-details .border-line {
        border: none;
    }

    .product-details .text-holder h2 {
        font-size: 17px;

    }

    .product-details .text-holder p {
        font-size: 14px;
    }

    .product-details .text-overview h4 {
        font-size: 13px;
        margin-right: 7px;
        padding-right: 19px;
    }
    .product-details .details-text {
        margin-bottom: 0%;
    }
}