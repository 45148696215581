.our-product {
    margin-top: 5%;
    clip-path: polygon(0 5%, 51% 0, 100% 5%, 100% 99%, 51% 96%, 0 100%);
    background: #F1F1F1 0% 0% no-repeat padding-box;
    padding-top: 75px;
    padding-bottom: 5%;
}

.our-product .product-card .image-holder {
    background-color: #FFF;
    border-radius: 10px;
    border-radius: 15px;

}

.our-product .product-card .image-holder .product-img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 15px;
}

.our-product .product-card h3 {
    color: #202020;
    font-size: 18px;
    font-weight: bold;
    margin-top: 16px;
    margin-left: 10px;
}

@media(min-width:0px) and (max-width:992px) {
    .our-product .product-card h3 {
        font-size: 16px;
    }

    .our-product {
        padding-bottom: 12%;
    }
}