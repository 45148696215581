.we-are-leader {
    margin-bottom: 5%;
}

.we-are-leader .heading-holder p {
    font-size: 18px;
    font-weight: 900;
    color: #202020;
}

.we-are-leader .heading-holder .border-line {
    width: 76px;
    height: 2px;
    background-color: #964400;
    margin-top: 11px;
}


.we-are-leader .text-holder {
    text-align: justify;
}

.we-are-leader .text-holder h3 {
    font-size: 18px;
    font-weight: bold;
}

.we-are-leader .text-holder h4 {
    font-size: 16px;
    font-weight: 500;
}

.we-are-leader .text-holder p {
    font-size: 16px;
}

.we-are-leader .image-holder .we-img {
    width: 100%;
    aspect-ratio: 1 / 0.6;
    border-radius: 5px;
}

@media(min-width:0px) and (max-width:767px) {
    .we-are-leader .text-holder h3 {
        font-size: 15px;

    }

    .we-are-leader .text-holder h4 {
        font-size: 14px;

    }

    .we-are-leader .text-holder p {
        font-size: 14px;
    }

    .we-are-leader .heading-holder p {
        font-size: 14px;

    }

    .we-are-leader {
        margin-top: 4%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .we-are-leader .text-holder h3 {
        font-size: 16px;

    }

    .we-are-leader .text-holder h4 {
        font-size: 15px;

    }

    .we-are-leader .text-holder p {
        font-size: 14px;
    }

    .we-are-leader .heading-holder p {
        font-size: 15px;

    }
    .we-are-leader {
        margin-top: 3%;
    }
}


@media(min-width:992px) and (max-width:1200px) {
    .we-are-leader .text-holder h3 {
        font-size: 16px;

    }

    .we-are-leader .text-holder h4 {
        font-size: 15px;

    }

    .we-are-leader .text-holder p {
        font-size: 14px;
    }
    .we-are-leader {
        margin-top: 2%;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .we-are-leader {
        margin-top: 2%;
    }
}