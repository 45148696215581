.logo-slider {
    margin-top: 0%;
    margin-bottom: 5%;
}

.logo-slider .first-image .logo-company {
    width: 100%;
    height: 100%;
    /* filter: grayscale(10.6); */
    /* filter: invert(86%) sepia(5%) saturate(17%) hue-rotate(17deg) brightness(87%) contrast(86%); */
}

.logo-slider .second-image .logo-company2 {
    width: 100%;
    height: 100%;
}

.logo-slider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-slider .mySwiper .sponsers-logo {
    position: relative;
    cursor: pointer;

}

.logo-slider .mySwiper .sponsers-logo .first-image {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.35s ease;
    opacity: 1;
    z-index: 1;
}

.logo-slider .mySwiper .sponsers-logo .second-image {
    opacity: 0;
    z-index: 2;
}

.logo-slider .mySwiper .sponsers-logo:hover .first-image {
    opacity: 0;
}

.logo-slider .mySwiper .sponsers-logo:hover .second-image {
    opacity: 1;
    animation: beat 0.6s infinite alternate;
}

/* .logo-slider .mySwiper .sponsers-logo img.logo-company,
.logo-slider .mySwiper .sponsers-logo img.logo-company2 {
    width: 100%;
    height: auto; 
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.35s ease; 
} */

/* .logo-slider .mySwiper .sponsers-logo img.logo-company {
    opacity: 1;
    z-index: 1;
  
}

.logo-slider .mySwiper .sponsers-logo img.logo-company2 {
    opacity: 0;
    z-index: 2;
  
}

.logo-slider .mySwiper .sponsers-logo:hover img.logo-company {
    opacity: 0;
}

.logo-slider .mySwiper .sponsers-logo:hover img.logo-company2 {
    opacity: 1;
    animation: beat 0.6s infinite alternate;
} */