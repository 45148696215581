.Certificates .Certificates_swiper {
  padding-top: 10px;
}

.Certificates .Certificates_swiper .Certificates-card {
  height: 400px;
  box-shadow: 0px 0px 8px 0px #ccc;
  border-radius: 10px;
}

.Certificates .Certificates_swiper .Certificates-card .Certificates_img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;

  object-fit: contain;
}
