.blog-details {
    margin-bottom: 5%;
}

.blog-details .image-holder .details-img {
    width: 100%;
    aspect-ratio: 1 / 0.4;
    border-radius: 10px;
}

.blog-details .text-holder h2 {
    font-size: 28px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.blog-details .icon-holder span {
    font-size: 12px;
    font-weight: 500;
}

.blog-details .icon-holder .fa-icon {
    font-size: 12px;
    margin-right: 5px;
}

.blog-details .details-holder {
    position: relative;
}

.blog-details .details-holder .view-details {
    position: absolute;
    bottom: 0;
    left: 0;
}

.blog-details .paragraph-text h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.blog-details .paragraph-text p {
    font-size: 15px;
}

@media(min-width:0px) and (max-width:767px) {
    .blog-details .text-holder h2 {
        font-size: 16px;
        margin-top: 10px;
    }

    .blog-details .details-holder {
        position: unset;
    }

    .blog-details .details-holder .view-details {
        position: unset;

    }

    .blog-details .paragraph-text h3 {
        font-size: 16px;

    }

    .blog-details .paragraph-text p {
        font-size: 13px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .blog-details .text-holder h2 {
        font-size: 17px;
    }

    .blog-details .paragraph-text h3 {
        font-size: 17px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .blog-details .text-holder h2 {
        font-size: 22px;
    }
}