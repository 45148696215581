.gellery-modal .modal-content {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    /* opacity: 0.9; */
    padding: 20px 40px;
    border-radius: 0%;
}

.gellery-modal .gallery-image .all-galley-img {
    width: 100%;
    aspect-ratio: 1/0.6;
    border-radius: 10px;
}

.gellery-modal .silder-btn {
    position: relative;
}

.gellery-modal .silder-btn .back-btn,
.gellery-modal .silder-btn .next-btn {
    cursor: pointer;
    position: absolute;
    bottom: 299px;
    z-index: 2;
    font-size: 20px;
    width: 30px;
    height: 30px;
    background: #707070 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    transition: color 0.2s ease, background 0.2s ease;
}

.gellery-modal .silder-btn .back-btn {
    left: -78px;
}

.gellery-modal .silder-btn .next-btn {
    right: -74px;
}

.gellery-modal .swiper-button-prev.swiper-button-disabled,
.gellery-modal .swiper-button-next.swiper-button-disabled {
    display: none;
}

.gellery-modal .silder-btn .back-btn:hover,
.gellery-modal .silder-btn .next-btn:hover {
    color: #964400;
    width: 30px;
    height: 30px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    text-align: center;
}

/* .gellery-modal .silder-btn .back-btn {
    content: url('../../../../../public/assets/images/icon/prev-icon.png');
    cursor: pointer;
    position: absolute;
    bottom: 299px;
    left: -78px;
    z-index: 2;
}

.gellery-modal .silder-btn .next-btn {
    content: url('../../../../../public/assets/images/icon/next-icon.png');
    cursor: pointer;
    position: absolute;
    bottom: 299px;
    right: -74px;
    z-index: 2;
}

.gellery-modal .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    display: none;
} */

.gellery-modal .modal-header {
    border-bottom: none;
    padding: 0;
}

.gellery-modal .modal-header .btn-close {
    background-image: url('../../../../../public/assets/images/icon/circle.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: -85px;
    opacity: 1;

}

.gellery-modal {
    opacity: 1;
    backdrop-filter: blur(19px);
    -webkit-backdrop-filter: blur(19px);
    z-index: 99999999999;
}

@media(min-width:0px) and (max-width:360px) {
    .gellery-modal .silder-btn .next-btn {
        bottom: 68px;
        right: -30px;
        width: 21px;
    }

    .gellery-modal .silder-btn .back-btn {
        width: 21px;
        bottom: 68px;
        left: -33px;
    }

    .gellery-modal .modal-content {
        padding: 0px 12px;
    }

    .gellery-modal .modal-header .btn-close {
        top: -25px;
        right: 1px;
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
    }
}

@media(min-width:360px) and (max-width:486px) {
    .gellery-modal .silder-btn .next-btn {
        bottom: 93px;
        right: -30px;
        width: 21px;
    }

    .gellery-modal .silder-btn .back-btn {
        width: 21px;
        bottom: 93px;
        left: -33px;
    }

    .gellery-modal .modal-content {
        padding: 0px 12px;
    }

    .gellery-modal .modal-header .btn-close {
        top: -25px;
        right: 1px;

    }
}

@media(min-width:486px) and (max-width:767px) {
    .gellery-modal .silder-btn .next-btn {
        bottom: 119px;
        right: -30px;
        width: 21px;
    }

    .gellery-modal .silder-btn .back-btn {
        width: 21px;
        bottom: 119px;
        left: -33px;
    }

    .gellery-modal .modal-content {
        padding: 0px 12px;
    }

    .gellery-modal .modal-header .btn-close {
        top: -25px;
        right: 1px;

    }
}

@media(min-width:768px) and (max-width:992px) {
    .gellery-modal .silder-btn .next-btn {
        bottom: 119px;
        right: -49px;
    }

    .gellery-modal .silder-btn .back-btn {
        bottom: 119px;
        left: -50px;
    }

    .gellery-modal .modal-content {
        padding: 0px 12px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .gellery-modal .silder-btn .next-btn {
        bottom: 190px;
    }

    .gellery-modal .silder-btn .back-btn {
        bottom: 190px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .gellery-modal .modal-header .btn-close {
        right: -44px;
    }


}