.category-product {
    margin-bottom: 5%;
}

.category-product .text-holder h3 {
    color: #0078D1;
    font-size: 20px;
    font-weight: bold;
    border-left: 4px solid #0078D1;
    padding-left: 10px;
}

.category-product .input-group-text {
    background: #0078D1 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFF;
    height: 38px;

}

.category-product .search-icon {
    font-weight: 500;
    font-size: 18px;
}

.category-product .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 13px;
    height: 38px;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;

}

.category-product .form-control::placeholder {
    color: #707070;
}



@media(min-width:0px) and (max-width:1200px) {
    .category-tab .address-holder p {
        font-size: 12px;
        color: #000;
    }
}