.video-holder {
    position: relative;
}

.video-holder .video-class-grid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);
}

.video-holder .overlay-banner {
    position: absolute;
    width: 100%;
    height: 99%;
    z-index: 1;
    top: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000040 25%, #0078D1 100%) 0% 0% no-repeat padding-box;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);
}

.video-holder .heading-holder {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.video-holder .heading-holder .image-holder .banner-img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.video-holder .heading-holder h1 {
    position: relative;
    font-size: 233px;
    font-weight: bold;
    color: #ffffff4f;
    z-index: 2;
    opacity: 0.7;
}

.video-holder .heading-holder h2 {
    position: relative;
    font-size: 59px;
    font-weight: bold;
    color: #FFF;
    z-index: 2;
    opacity: 1;
}

.video-holder .heading-holder h3 {
    position: relative;
    font-size: 115px;
    font-weight: bold;
    color: #ffffff4f;
    z-index: 2;
    opacity: 0.7;
}

.video-holder .button-holder {
    position: absolute;
    bottom: 108px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

@media(min-width:0px) and (max-width:360px) {
    .video-holder .heading-holder h2 {
        font-size: 15px;
    }

    .video-holder .heading-holder {
        top: 0%;
        left: 0px;
        right: 0px;
        transform: none;
    }

    .video-holder .button-holder {
        bottom: 23px;
        left: 0;
        right: 0;
        transform: none;
    }

    .video-holder .heading-holder .image-holder .banner-img {
        width: 184px;
        /* height: 43px; */
        object-fit: contain;
    }

    .video-holder .heading-holder h1 {
        font-size: 50px;
    }

    .video-holder .heading-holder h3 {
        font-size: 30px;
    }
}

@media(min-width:360px) and (max-width:486px) {
    .video-holder .heading-holder h2 {
        font-size: 15px;
    }

    .video-holder .heading-holder {
        top: 0%;
        left: 0px;
        right: 0px;
        transform: none;
    }

    .video-holder .button-holder {
        bottom: 32px;
        left: 0;
        right: 0;
        transform: none;
    }

    .video-holder .heading-holder .image-holder .banner-img {
        width: 226px;
        /* height: 65px; */
        object-fit: contain;
    }

    .video-holder .heading-holder h1 {
        font-size: 60px;
    }

    .video-holder .heading-holder h3 {
        font-size: 40px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    .video-holder .heading-holder h2 {
        font-size: 24px;
    }

    .video-holder .button-holder {
        bottom: 39px;
        left: 51%;
    }

    .video-holder .heading-holder {
        top: 10%;
        left: 0px;
        right: 0px;
        transform: none;
    }

    .video-holder .heading-holder .image-holder .banner-img {
        /* width: 226px; */
        height: 65px;
        object-fit: contain;
    }

    .video-holder .heading-holder h1 {
        font-size: 80px;
    }

    .video-holder .heading-holder h3 {
        font-size: 50px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .video-holder .heading-holder h2 {
        font-size: 24px;
    }

    .video-holder .button-holder {
        bottom: 39px;
        left: 51%;
    }

    .video-holder .heading-holder h1 {
        font-size: 86px;
    }

    .video-holder .heading-holder h3 {
        font-size: 44px;
    }

    .video-holder .heading-holder {
        top: 45%;
    }
}


@media(min-width:992px) and (max-width:1200px) {
    .video-holder .heading-holder h2 {
        font-size: 30px;
    }

    .video-holder .button-holder {
        bottom: 49px;
    }

    .video-holder .heading-holder h1 {
        font-size: 120px;
    }

    .video-holder .heading-holder h3 {
        font-size: 60px;
    }

    .video-holder .heading-holder {
        top: 47%;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .video-holder .heading-holder h2 {
        font-size: 39px;
    }

    .video-holder .button-holder {
        bottom: 100px;
    }

    .video-holder .heading-holder h1 {
        font-size: 150px;
    }

    .video-holder .heading-holder h3 {
        font-size: 70px;
    }

    .video-holder .button-holder {
        bottom: 76px;
    }
}

@media(min-width:1400px) and (max-width:1500px) {
    .video-holder .heading-holder h2 {
        font-size: 45px;
    }

    .video-holder .heading-holder h1 {
        font-size: 150px;
    }

    .video-holder .heading-holder h3 {
        font-size: 75px;
    }

    .video-holder .button-holder {
        bottom: 76px;
    }
}

@media(min-width:1500px) and (max-width:1700px) {
    .video-holder .heading-holder h2 {
        font-size: 45px;
    }

    .video-holder .heading-holder h1 {
        font-size: 150px;
    }
}