.serives-tabs {
    margin-bottom: 5%;
}

.serives-tabs .nav-pills {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border-radius: 28px;
    padding: 12px 8px;
    width: 273px;
    margin: auto;

}


.serives-tabs .nav-pills .nav-link.active {
    border: 2px solid #004E87;
    border-radius: 50px;
    background: #0078D1 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #FFF;
    font-size: 15px;
    padding: 5px 20px;

}

.serives-tabs .nav-pills .nav-link {
    color: #202020;
    font-size: 15px;
    border: 2px solid #A7A7A7;
    background: #DEDEDE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    padding: 5px 20px;

}

.serives-tabs .text-holder h2 {
    font-size: 19px;
    font-weight: 500;
    margin: 34px 0px;
}

.serives-tabs .text-holder p {
    font-size: 17px;
    margin-bottom: 20px;
}

.serives-tabs .card {
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 50px;
}

.serives-tabs .card .card-body {
    background-color: #0078D1;
    border-radius: 0px 0px 15px 15px;
}

.serives-tabs .card .image-holder {
    background-color: #FFF;
    border-radius: 10px;

}

.serives-tabs .card .product-img {
    width: 100%;
    aspect-ratio: 1/0.8;
    border-radius: 15px 15px 0px 0px;
}

.serives-tabs .card .card-title {
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.serives-tabs .card .icon-holder {
    background: #FFF;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 52px;
    right: 0px;
    z-index: 1;
}

.serives-tabs .card .icon-holder .right-arrow {
    color: #004E87;
    font-size: 18px;
    margin-top: 7px;
}

.serives-tabs .card .icon-holder .right-arrow {
    transition: transform 0.3s ease;
}



.serives-tabs .card:hover .icon-holder .right-arrow {
    transform: translateX(15px);
    color: #964400;
}

.serives-tabs .card .overlay-color {
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #964400 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px 15px 0px 0px;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    bottom: 71px;
    height: 0%;
    transition: height 0.3s ease;
}

.serives-tabs .card:hover:hover .overlay-color {
    height: 79%;

}

.ExportServices .serives-tabs {
    margin-bottom: 2%;
}

@media(min-width:0px) and (max-width:767px) {
    .serives-tabs .text-holder h2 {
        font-size: 15px;
        margin: 27px 0px;
    }

    .serives-tabs .text-holder p {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .serives-tabs .card {
        margin-bottom: 9px;
        margin-top: 16px;
    }

    .serives-tabs .card .card-title {
        font-size: 16px;
    }

    .serives-tabs .nav-pills .nav-link.active {
        font-size: 14px;
        padding: 3px 15px;

    }

    .serives-tabs .nav-pills .nav-link {
        font-size: 14px;
        padding: 3px 15px;

    }

    .serives-tabs {
        margin-top: 2%;
    }
    .serives-tabs .nav-pills {
        width: 245px;
         
      }
}

@media(min-width:768px) and (max-width:992px) {
    .serives-tabs .text-holder h2 {
        font-size: 15px;
        margin: 34px 0px;
    }

    .serives-tabs .card .card-title {
        font-size: 16px;
    }

    .serives-tabs .text-holder p {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .serives-tabs {
        margin-top: 2%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .serives-tabs .nav-pills .nav-link.active {
        font-size: 14px;
        padding: 3px 15px;

    }

    .serives-tabs .nav-pills .nav-link {
        font-size: 14px;
        padding: 3px 15px;

    }

    .serives-tabs .card .card-title {
        font-size: 17px;

    }

    .serives-tabs {
        margin-top: 2%;
    }

    .serives-tabs .nav-pills {
      width: 245px;
       
    }
}