.category-product .card {
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease-in-out;
    min-height: 322px;
    border: none;
    background-color: transparent;
}


.category-product .card .product-icon {
    width: 100%;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    aspect-ratio: 1/1;
    background-position: center;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 15px;
    transition: background 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.category-product .card:hover .product-icon {
    background: #E6E6E6 0% 0% no-repeat padding-box;
    border-radius: 15;
}

.category-product .card .product-icon .product-img {
    width: 200px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
}

/* .category-product .card .card-body {
 transition: background 0.3s ease-in-out, border-radius 0.3s ease-in-out;
} */

.category-product .overlayyy {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 0;
    width: 100%;
    border-radius: 15px;
    background: #DCF0FF 0% 0% no-repeat padding-box;
    transition: height 0.4s ease-in-out;
    z-index: -1;
    display: grid;
    align-items: end;
}

.category-product .card:hover .overlayyy {
    height: 100%;

}

.category-product .card:hover .card-body {
    /* background: #DCF0FF 0% 0% no-repeat padding-box; */
    border-radius: 0 0 10px 10px;
}

.category-product .product-name {
    font-size: 20px;
    font-weight: 500;
}

.category-product .get-details {
    font-size: 13px;
    color: #0078D1;
    opacity: 0;
    padding-left: 16px;
    padding-bottom: 10px;
}

.category-product .card:hover .get-details {
    opacity: 1;

}

.category-product a {
    color: #000;
}

@media(min-width:0px) and (max-width:1200px) {
    .category-product .product-name {
        font-size: 16px;
        font-weight: 500;
    }

    .category-product .text-holder h3 {
        font-size: 18px;

    }
}