.faq-question {
    margin-bottom: 5%;
}

.faq-question .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
}

.faq-question .accordion-button {
    color: #000;
    background-color: transparent;
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
}

.faq-question .accordion-body p {
    font-size: 15px;
    text-align: justify;

}

.faq-question .accordion-body span {
    font-size: 15px;
    margin-right: 15px;
}

.faq-question .accordion-button:focus {
    box-shadow: none;
}

.faq-question .accordion-item {
    background-color: transparent;
    border: none;
}

.faq-question .accordion-button:not(.collapsed)::after {
    background: url("../../../public/assets/images/icon/up-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: none;
    width: 34px;
    height: 34px;
}

.faq-question .accordion-button::after {
    background-image: url("../../../public/assets/images/icon/down-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 34px;
    height: 34px;
}

@media(min-width:0px) and (max-width:767px) {
    .faq-question .accordion-button:not(.collapsed) {
        font-size: 15px;
    }

    .faq-question .accordion-button {
        font-size: 15px;

    }

    .faq-question .accordion-body p {
        font-size: 12px;

    }

    .faq-question .accordion-button:not(.collapsed)::after {
        width: 24px;
        height: 24px;
    }

    .faq-question .accordion-button::after {
        width: 24px;
        height: 24px;
    }

    .faq-question .accordion-body span {
        font-size: 13px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .faq-question .accordion-button:not(.collapsed) {
        font-size: 18px;
    }

    .faq-question .accordion-button {
        font-size: 18px;

    }

}