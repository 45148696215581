.contact-us {
  margin-bottom: 8%;
}

.contact-us .bg-image {
  background-image: url("../../../public/assets/images/contact-us/contact-us-bann1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 498px;
}

.contact-us .heading-row {
  margin-top: -406px;
  margin-bottom: 38px;
}

.contact-us .form-row {
  /* margin-top: -429px; */
  display: flex;
  /* Add flexbox */
}

.contact-us .form-background,
.contact-us .map-section {
  flex: 1;
  /* Ensure equal height */
}

.contact-us .heading-name h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-left: 105px;
}

.contact-us .map-text {
  cursor: pointer;
}

.contact-us .phone-input-container {
  display: flex !important;
  align-items: center !important;
}

.contact-us .phone-input-button {
  flex-shrink: 0 !important;
  margin-right: 10px !important;
  border-right: 5px solid #fff;
  padding: 0px 30px;
  /* Adjust the space between the flag and input as needed */
}

.contact-us .phone-input-field {
  flex-grow: 1 !important;
}

.contact-us .react-tel-input .form-control {
  font-size: 16px;
  /* background: #f3f3f3 0% 0% no-repeat padding-box; */
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 7.5px 16px 9.5px 130px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}

.contact-us .form-control {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  height: 40px;
}

.contact-us .form-control:focus {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  height: 40px;
}

.contact-us .form-select {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  height: 40px;
}

.contact-us .textarea-class {
  height: auto !important;
}

.contact-us .form-select:focus {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  height: 40px;
}

.contact-us .form-control::placeholder {
  color: #707070;
  font-size: 14px;
}

.contact-us .react-select-container {
  margin-bottom: 1rem;
}

.contact-us .react-select__control {
  height: 38px;
}

.contact-us .react-select__placeholder {
  color: #6c757d;
  /* Bootstrap's default placeholder color */
}

.contact-us .form-background {
  background-color: #fff;
  padding: 30px 65px 10px 65px;
  box-shadow: 0px 3px 6px #00000029;
}

.contact-us .contact-details {
  padding: 30px 28px 10px 28px;
  background-color: #004e87;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
}

.contact-us .contact-details .location-name h3 {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}

.contact-us .icon-holder .call-icon {
  color: #fff;
}

.contact-us .address-holder p {
  font-size: 15px;
  color: #fff;
}

.contact-us .border-holder {
  border-bottom: 1px solid #fff;
}

.contact-us .social-icon .border-icon {
  background-color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  color: #0078d1;
  margin-right: 10px;
}

.contact-us .social-icon .fb-icon {
  margin-top: 5px;
}

.contact-us .gmap_iframe {
  width: 100%;
  height: 100%;
}

.contact-us .map-section {
  position: relative;
}

.contact-us .map-section .gmap_canvas {
  height: 60vh;
  margin-top: 10%;
}

.contact-us .map-section .close-button {
  position: absolute;
  top: 0px;
  right: 18px;
}

.contact-us .close-button .icon-logo {
  width: 29px;
}

.contact-us .map-section .mapouter {
  background: #fff;
  margin-top: -137px;
}

.flip-horizontal-top {
  -webkit-animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

@keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.4s linear both;
  animation: flip-horizontal-bottom 0.4s linear both;
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

.contact-us .form-control,
.contact-us .phone-input-field,
.contact-us .react-select__control {
  /* Ensure elements are visible and interactable */
  pointer-events: auto;
  /* Reset pointer events if overridden */
  cursor: text;
  /* Set cursor style appropriate for input fields */
}

@media (min-width: 0px) and (max-width: 767px) {
  .contact-us .form-background {
    padding: 30px 19px 1px 19px;
  }

  .contact-us .contact-details {
    padding: 30px 24px 10px 25px;
  }

  .contact-us .contact-details .location-name h3 {
    font-size: 20px;
  }

  .contact-us .address-holder p {
    font-size: 14px;
  }

  .contact-us .heading-name h2 {
    font-size: 24px;
    text-align: center;
    margin-left: 0px;
  }

  .contact-us .heading-row {
    margin-top: -460px;
    margin-bottom: 27px;
  }

  .contact-us .map-section .mapouter {
    margin-top: -54px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .contact-us .form-background {
    padding: 30px 19px 1px 19px;
  }

  .contact-us .contact-details {
    padding: 30px 24px 10px 25px;
  }

  .contact-us .contact-details .location-name h3 {
    font-size: 21px;
  }

  .contact-us .address-holder p {
    font-size: 14px;
  }

  .contact-us .map-section .mapouter {
    margin-top: -75px;
  }

  .contact-us .heading-name h2 {

    margin-left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contact-us .map-section .mapouter {
    margin-top: -106px;
  }
}