.meet-section {
    background-image: url("../../../../public/assets/images/home/enquiry-banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 0%;
    padding-top: 75px;
    padding-bottom: 5%;
    margin-top: -50px;
    /* clip-path: polygon(0 3%, 53% 0, 100% 3%, 100% 100%, 49% 96%, 0 100%); */
}

.bg-color {
    background-color: #F1F1F1;
}

.meet-section .heading-holder h2 {
    font-size: 35px;
    font-weight: bold;
}

.meet-section .heading-holder h3 {
    color: #0078D1;
    font-size: 35px;
    font-weight: bold;
}

.meet-section .heading-holder p {
    font-size: 18px;
    font-weight: bold;
}

.meet-section .heading-holder span {
    color: #0078D1;
}

.meet-section .button-holder {
    margin-top: 22%;
}

.QualityAssurance .meet-section {
    margin-bottom: -50px;
}

.ExportServices .meet-section {
    margin-top: -12px;
}

@media(min-width:0px) and (max-width:767px) {
    .meet-section .heading-holder {
        margin-top: 27px;
    }

    .meet-section .heading-holder h2 {
        font-size: 20px;
    }

    .meet-section .heading-holder h3 {
        font-size: 20px;

    }

    .meet-section .heading-holder p {
        font-size: 12px;
        font-weight: bold;
    }

    .QualityAssurance .meet-section {
        margin-bottom: -70px;
        padding-bottom: 30%;
    }

    .meet-section {
        padding-top: 44px;
        padding-bottom: 18%;

    }

    .meet-section .button-holder {
        margin-top: 4%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .meet-section .heading-holder h2 {
        font-size: 25px;
    }

    .meet-section .heading-holder h3 {
        font-size: 25px;

    }

    .meet-section .heading-holder p {
        font-size: 14px;
        font-weight: bold;
    }

    .QualityAssurance .meet-section {
        margin-bottom: -62px;

    }

    .meet-section .button-holder {
        margin-top: 28%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .meet-section .heading-holder h2 {
        font-size: 30px;
    }

    .meet-section .heading-holder h3 {
        font-size: 30px;

    }

    .meet-section .heading-holder p {
        font-size: 15px;
        font-weight: bold;
    }

    .meet-section .button-holder {
        margin-top: 25%;
    }
}