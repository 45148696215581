.terms-condition {
    margin-top: 2%;
    margin-bottom: 5%;
}

.terms-condition .text-holder h2 {
    font-size: 20px;
    font-weight: bold;
}

.terms-condition .text-holder p {
    font-size: 17px;
    margin-bottom: 5px;
}

@media(min-width:0px) and (max-width:992px) {
    .terms-condition .text-holder h2 {
        font-size: 18px;

    }

    .terms-condition .text-holder p {
        font-size: 14px;

    }
}