.our-professionalism {
    margin-top: 2%;
}

.About_Us .our-professionalism {
    margin-top: 6%;
}

.our-professionalism .heading-holder h2 {
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 25px;
}

.our-professionalism .heading-holder p {
    font-size: 18px;
    margin-bottom: 45px;
}

@media(min-width:0px) and (max-width:486px) {

    .our-professionalism .heading-holder h2 {
        font-size: 22px;
    }

    .our-professionalism .heading-holder p {
        font-size: 14px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    .our-professionalism .heading-holder h2 {
        font-size: 25px;
    }

    .our-professionalism .heading-holder p {
        font-size: 14px;
    }

}

@media(min-width:768px) and (max-width:992px) {
    .our-professionalism .heading-holder h2 {
        font-size: 30px;
    }

    .our-professionalism .heading-holder p {
        font-size: 15px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .our-professionalism .heading-holder h2 {
        font-size: 35px;
    }
}