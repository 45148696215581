.faq-banner {
    background: #0078D1 0% 0% no-repeat padding-box;
    padding: 50px;
}

.faq-banner .heading-holder h1 {
    font-size: 30px;
    color: #FFF;
    font-weight: 600;
}

@media(min-width:0px) and (max-width:767px) {
    .faq-banner {
        padding: 31px;
    }

    .faq-banner .heading-holder h1 {
        font-size: 25px;

    }
}